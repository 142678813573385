/* Base icon styles */
.pg {
  display: inline-block;
  font: normal normal normal 14px/1 pages-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Responsive styles for screens of 800px and below */
@media only screen and (max-width: 800px) {
  /* Adjust base font size for icons */
  .pg {
    font-size: 12px; /* Reduce size for smaller screens */
  }

  [class^="pg-"],
  [class*=" pg-"] {
    font-size: 12px; /* Apply smaller size to all icons */
  }

  /* Specific adjustments for individual icons */
  .pg-link:before,
  .pg-menu:before,
  .pg-alt_menu:before,
  .pg-thumbs:before,
  .pg-world:before,
  .pg-boxnet:before,
  .pg-signals:before,
  .pg-crop:before,
  .pg-folder_alt:before,
  .pg-folder:before,
  .pg-theme:before,
  .pg-battery_empty:before,
  .pg-battery:before,
  .pg-note:before,
  .pg-server_hard:before,
  .pg-servers:before,
  .pg-menu_justify:before,
  .pg-credit_card:before,
  .pg-fullscreen_restore:before,
  .pg-fullscreen:before,
  .pg-minus:before,
  .pg-minus_circle:before,
  .pg-plus_circle:before,
  .pg-refresh_new:before,
  .pg-close_line:before,
  .pg-close:before,
  .pg-arrow_down:before,
  .pg-arrow_left_line_alt:before,
  .pg-arrow_left:before,
  .pg-arrow_lright_line_alt:before,
  .pg-arrow_maximize_line:before,
  .pg-arrow_maximize:before,
  .pg-arrow_minimize_line:before,
  .pg-arrow_minimize:before,
  .pg-arrow_right:before,
  .pg-arrow_up:before,
  .pg-more:before,
  .pg-bag:before,
  .pg-bag1:before,
  .pg-bold:before,
  .pg-calender:before,
  .pg-camera:before,
  .pg-centeralign:before,
  .pg-charts:before,
  .pg-clock:before,
  .pg-comment:before,
  .pg-contact_book:before,
  .pg-credit_card_line:before,
  .pg-cupboard:before,
  .pg-desktop:before,
  .pg-download:before,
  .pg-eraser:before,
  .pg-extra:before,
  .pg-form:before,
  .pg-grid:before,
  .pg-home:before,
  .pg-image:before,
  .pg-inbox:before,
  .pg-indent:before,
  .pg-italic:before,
  .pg-laptop:before,
  .pg-layouts:before,
  .pg-layouts2:before,
  .pg-layouts3:before,
  .pg-layouts4:before,
  .pg-leftalign:before,
  .pg-like:before,
  .pg-like1:before,
  .pg-lock:before,
  .pg-mail:before,
  .pg-map:before,
  .pg-menu_lv:before,
  .pg-movie:before,
  .pg-ordered_list:before,
  .pg-outdent:before,
  .pg-phone:before,
  .pg-plus:before,
  .pg-power:before,
  .pg-printer:before,
  .pg-refresh:before,
  .pg-resize:before,
  .pg-right_align:before,
  .pg-save:before,
  .pg-search:before,
  .pg-sent:before,
  .pg-settings_small_1:before,
  .pg-settings_small:before,
  .pg-settings:before,
  .pg-shopping_cart:before,
  .pg-social:before,
  .pg-spam:before,
  .pg-suitcase:before,
  .pg-tables:before,
  .pg-tablet:before,
  .pg-telephone:before,
  .pg-text_style:before,
  .pg-trash_line:before,
  .pg-trash:before,
  .pg-ui:before,
  .pg-underline:before,
  .pg-unordered_list:before,
  .pg-video:before {
    font-size: 1.2em; /* Adjust the size of icons */
  }

  /* Further adjustments for specific icons can go here */
}
