/* Base Styles for Sidebar */
.page-sidebar {
  width: auto;
  background-color: #fff;
  z-index: 1000;
  left: 0;
  position: fixed;
  bottom: 0;
  top: 0;
  right: auto;
  overflow: hidden;
  transition: transform 400ms cubic-bezier(.05, .74, .27, .99);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

.hamburger-menu {
  display: none;
  position: fixed;
  color: #fff;
  top: 17.5px;
  left: 16px;
  margin-bottom: 40px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2001;
}

@media only screen and (max-width: 800px) {
  .hamburger-menu {
    display: block;
    z-index: 500
  }
}


.page-sidebar a,
.page-sidebar button {
  color: #788195;
  margin-left: 10px;
}

.page-sidebar a:hover,
.page-sidebar button:hover {
  color: #000;
  background-color: transparent !important;
}

.page-sidebar .sidebar-header {
  height: 62px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  padding: 10px 20px;
  z-index: 10;
  position: relative;
}

/* Sidebar Menu */
.page-sidebar .sidebar-menu {
  height: calc(100% - 50px);
  width: 100%;
 
}

.page-sidebar .sidebar-menu .menu-items {
  list-style: none;
  margin: 0;
  padding: 30px 0;
  overflow: auto;
  height: calc(100% - 10px);
}

.page-sidebar .sidebar-menu .menu-items>li {
  display: flex;
  padding: 10px 20px;
  align-items: center;
}

.page-sidebar .sidebar-menu .menu-items>li:hover,
.page-sidebar .sidebar-menu .menu-items>li.active {
  background-color: #f5f7f9;
}

/* Responsive Styles */
@media only screen and (max-width: 800px) {
  .page-sidebar {
    width: auto;
    left: 0;
    transform: translateX(-100%);
    z-index: 2000;
    box-shadow: 1px 4px 11px 0px #CEBDE440;
  }

  .page-sidebar.visible {
    transform: translateX(0);
    width: auto;
  }

  .page-sidebar .sidebar-header {
    padding: 0 12px;
  }

  .page-sidebar .sidebar-menu .menu-items>li>a {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .page-sidebar {
    width: auto;
    box-shadow: 1px 4px 11px 0px #CEBDE440;

  }

  

  .page-sidebar .sidebar-header {
    height: 48px;
    line-height: 48px;
    padding: 0 12px;
  }

  .page-sidebar .sidebar-menu .menu-items {
    padding: 15px 0;
  }

  .page-sidebar .sidebar-menu .menu-items>li {
    padding: 10px 15px;
  }

  .page-sidebar .sidebar-menu .menu-items>li>a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .page-sidebar .sidebar-header {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
  }

  .page-sidebar .sidebar-menu .menu-items>li {
    padding: 8px 12px;
  }

  .page-sidebar .sidebar-menu .menu-items>li>a {
    font-size: 11px;
  }
}
